import { MenuProps, Pagination, Space, Spin } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { FlexboxGrid } from 'rsuite';

import { WidthWrapper } from '../../../components/Layout/styles';
import { OfferCardHorizontal } from '../../../components/OfferCard/OfferCardHorizontal';
import { Typography } from '../../../components/Typography/Typography';
import { useGetBookedOffersByUid } from '../../../firebase/hooks/getBookedOffersByUid';
import { ItemListTypes } from '../../../models/googleAnalytics.model';
import { OfferStatusState } from '../../../models/offers.model';
import { App } from '../../App/App';
import { useAuth } from '../../Auth/AuthContext';
import { AppRoutes } from '../../Routing/routing.model';
import { MenuWrapper } from '../components/MenuWrapper';
import { additionalNavigation } from '../Menu/AdditionalNavigation';
import { MenuItems } from '../Menu/Menu';
import { StyledArticle } from '../MyOffers/MojaTablicaOffers';

export const BookedOffers: React.FC = () => {
  const { push } = useHistory();
  const { currentUser } = useAuth();
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const itemsPerPage = 9;
  const onClick: MenuProps['onClick'] = (e) => {
    additionalNavigation(e.key);
    push(e.key);
  };

  const [offers, getBookedOffersByUid, loading] = useGetBookedOffersByUid();

  useEffect(() => {
    if (currentUser && currentUser.uid) {
      getBookedOffersByUid();
    }
  }, [currentUser, getBookedOffersByUid]);

  const bookedOffers = useMemo(() => offers.filter((o) => o.offerStatus.state === OfferStatusState.BOOKED), [offers]);

  const paginatedOffers = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return bookedOffers.slice(startIndex, endIndex);
  }, [bookedOffers, currentPage, itemsPerPage]);

  const handlePaginationChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <App>
      <MenuWrapper>
        <MenuItems
          onClick={onClick}
          defaultSelectedKeys={[AppRoutes.MOJA_TABLICA_BOOKED_OFFERS]}
          defaultOpenKeys={['sub1']}
        />
      </MenuWrapper>

      {loading ? (
        <Spin style={{ margin: '50px' }} />
      ) : bookedOffers && bookedOffers.length > 0 ? (
        <StyledArticle>
          <WidthWrapper>
            <Space direction="vertical" style={{ marginLeft: 20 }}>
              <Typography.H6> {t('PORTFOLIO.RESERVED_OFFERS')}</Typography.H6>
            </Space>
            <FlexboxGrid>
              {paginatedOffers.map((o, i) => (
                <FlexboxGrid.Item key={o.id + '-' + i} colspan={8}>
                  <OfferCardHorizontal
                    offer={o}
                    state={o.offerStatus.state as OfferStatusState}
                    addToFavoritesButton={false}
                    itemListType={ItemListTypes.RESERVED}
                  />
                </FlexboxGrid.Item>
              ))}
            </FlexboxGrid>
            <div className="flex justify-center my-4">
              <Pagination
                current={currentPage}
                pageSize={itemsPerPage}
                onChange={handlePaginationChange}
                total={bookedOffers?.length}
              />
            </div>
          </WidthWrapper>
        </StyledArticle>
      ) : (
        <Space direction="vertical" style={{ margin: 20 }}>
          {t('PORTFOLIO.NO_RESERVED_OFFERS')}
        </Space>
      )}
    </App>
  );
};
