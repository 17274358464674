import firebase from 'firebase/app';
import { useCallback, useState } from 'react';

import { Offer, OfferStatusState, SearchFormFilters } from '../../models/offers.model';
import { firestore } from '../firebase';
import { FirestoreCollection } from '../firebase.models';

import { useProcessDates } from './useProcessDates.hook';

/**
 * Hook:
 *  1) offers             - aktualna lista ofert
 *  2) getPaginatedOffers - pobiera oferty (paginacja) + filtruje po stronie klienta
 *  3) loading            - czy trwa zapytanie
 *  4) error              - ewentualny błąd
 *  5) totalOffers        - liczba dokumentów pasująca do filtrów (obliczana po stronie klienta)
 */
export function useGetOffers(): [
  Offer[],
  (formFilters?: SearchFormFilters, page?: number, pageSize?: number) => Promise<void>,
  boolean,
  firebase.FirebaseError,
  number
] {
  const [error, setError] = useState<firebase.FirebaseError>();
  const [loading, setLoading] = useState(false);
  const [offers, setOffers] = useState<Offer[]>([]);
  const [totalOffers, setTotalOffers] = useState<number>(0);

  // Zmienna do paginacji
  const [lastVisible, setLastVisible] = useState<firebase.firestore.QueryDocumentSnapshot | null>(null);
  const [previousFilters, setPreviousFilters] = useState<SearchFormFilters | undefined>(undefined);

  /**
   * Funkcja pobiera wyłącznie oferty z `offerStatus.state === ACTIVE`.
   * Następnie wszystkie dodatkowe filtry (minArea, maxPrice, freeFrom, province, itp.)
   * stosujemy PO STRONIE KLIENTA - dzięki czemu nie ma konfliktu nierówności w Firestore.
   */
  const getPaginatedOffers = useCallback(
    async (formFilters: SearchFormFilters = {}, page = 1, pageSize = 10) => {
      setLoading(true);
      setError(null);

      try {
        const filtersChanged = JSON.stringify(formFilters) !== JSON.stringify(previousFilters);
        if (filtersChanged) {
          // Reset przy zmianie filtrów
          setOffers([]);
          setLastVisible(null);
          setPreviousFilters(formFilters);
        }

        // 1. Budujemy zapytanie TYLKO z 'offerStatus.state === ACTIVE'
        //    + ewentualnie podstawowy limit (np. duży, żeby pobrać "zapas" ofert).
        let query = firestore
          .collection(FirestoreCollection.OFFERS_PUBLIC)
          .where('offerStatus.state', '==', OfferStatusState.ACTIVE)
          .limit(pageSize);

        if (page > 1 && !filtersChanged && lastVisible) {
          // Paginacja Firestore
          query = query.startAfter(lastVisible);
        }

        // 2. Pobierz dane
        const snapshot = await query.get();
        if (snapshot.empty) {
          // Brak wyników w tej "paczce"
          setOffers([]);
          setLastVisible(null);
          setLoading(false);
          setTotalOffers(0);
          return;
        }

        // 3. Konwersja dokumentów
        let newOffers = snapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            id: doc.id,
            ...data,
            ...useProcessDates(data),
          } as Offer;
        });

        // 4. Filtracja PO STRONIE KLIENTA (tak jak w "starym" kodzie)
        if (formFilters) {
          newOffers = newOffers.filter((offer) => {
            // minArea
            if (formFilters.minArea && offer.areaDetails?.surface < parseFloat(formFilters.minArea)) {
              return false;
            }
            // maxPrice
            if (formFilters.maxPrice && offer.price > parseFloat(formFilters.maxPrice)) {
              return false;
            }
            // premisesPurpose
            if (formFilters.premisesPurpose && offer.legalMainUse !== formFilters.premisesPurpose) {
              return false;
            }
            // freeFrom
            if (formFilters.freeFrom) {
              const freeFromTimestamp = firebase.firestore.Timestamp.fromDate(new Date(formFilters.freeFrom));
              const freeFromMs = freeFromTimestamp.toMillis();
              if (
                offer.availability > new Date(freeFromMs) ||
                (offer.availabilityTo && offer.availabilityTo < new Date(freeFromMs))
              ) {
                return false;
              }
            }
            // province
            if (formFilters.province && offer.address.officialAddress.province !== formFilters.province) {
              return false;
            }
            // county
            if (formFilters.county && offer.address.officialAddress.county !== formFilters.county) {
              return false;
            }
            // district
            if (formFilters.district && offer.address.officialAddress.district !== formFilters.district) {
              return false;
            }
            // estate
            if (formFilters.estate && offer.address.officialAddress.estate !== formFilters.estate) {
              return false;
            }
            // street
            if (formFilters.street && offer.address.officialAddress.street !== formFilters.street) {
              return false;
            }
            return true;
          });
        }

        // 5. Ustaw oferty w stanie
        setOffers(newOffers);

        // 6. Ustaw lastVisible (wskaźnik do paginacji w Firestore)
        const lastDoc = snapshot.docs[snapshot.docs.length - 1];
        setLastVisible(lastDoc || null);

        // 7. totalOffers = ile ofert "przeszło" przez filtr po stronie klienta
        setTotalOffers(newOffers.length);
      } catch (err: any) {
        console.error(err);
        setError(err);
      } finally {
        setLoading(false);
      }
    },
    [previousFilters, lastVisible]
  );

  return [offers, getPaginatedOffers, loading, error, totalOffers];
}
