import type { InputRef } from 'antd';
import { Button, Card, Input } from 'antd';
import React, { useRef, useState } from 'react';

import { firebaseFunctions } from '../../../firebase/firebase';
import { FirebaseCallableFunctions } from '../../../firebase/firebase.models';

import { NotificationType, openNotification } from './openNotification';

export const AddCreator: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const inputRef = useRef<InputRef>(null);

  const addCreatorClaim = async () => {
    setLoading(true);
    try {
      await firebaseFunctions.httpsCallable(FirebaseCallableFunctions.ADD_CREATOR_CLAIM)({ email: userEmail });
      openNotification(NotificationType.SUCCESS, 'Creator claim added successfully');

      if (inputRef.current) {
        inputRef.current.input.value = '';
      }
    } catch (error: any) {
      openNotification(NotificationType.ERROR, 'Error while adding creator claim - see console for more details');
      console.error('Error while adding creator claim:', error);
    }
    setLoading(false);
    setUserEmail('');
  };

  return (
    <Card title="Add Creator">
      <Input
        ref={inputRef}
        placeholder="Enter email"
        name="email"
        value={userEmail}
        onChange={(e) => setUserEmail(e.target.value)}
      />
      <Button type="primary" onClick={addCreatorClaim} loading={loading} block>
        Add Creator
      </Button>
    </Card>
  );
};
