import firebase from 'firebase';

import { FirebaseCallableFunctions } from '../../../../../../firebase/firebase.models';

export async function deleteUser(uid: string): Promise<[string, boolean]> {
  let response = '';
  let respError = false;
  try {
    const deleteAllUserData = firebase.functions().httpsCallable(FirebaseCallableFunctions.DELETE_ALL_USER_DATA);
    await deleteAllUserData({ userId: uid });
  } catch (error) {
    console.error('Wystąpił błąd podczas usuwania użytkownika:', error);
    response = 'Wystąpił błąd podczas usuwania użytkownika';
    respError = true;
  }

  return [response, respError];
}
