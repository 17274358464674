import { Col, Row, Typography } from 'antd';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { WidthWrapper } from '../../../components/Layout/styles';
import { AppRoutes } from '../../Routing/routing.model';

const Wrapper = styled.footer`
  padding: 16px 0;
  background-color: ${({ theme }) => theme.backgroundDarkBlue};
  color: ${({ theme }) => theme.textDarkSecondary};
  font-size: 1.1em;
`;

const Anchor = styled.a`
  color: ${({ theme }) => theme.textDarkSecondary};
  margin: 0 8px;
  text-decoration: underline;
  transition: color 0.3s ease;
  &:hover {
    color: ${({ theme }) => theme.textDarkPrimary};
  }
`;

const FooterContainer = styled(WidthWrapper)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

const RightSide = styled.div`
  @media (max-width: 768px) {
    margin-top: 10px;
  }
`;

const { Paragraph } = Typography;

export const FooterContent: React.FC = () => {
  const [version, setVersion] = useState('');

  useEffect(() => {
    fetch('/version.json')
      .then((response) => response.json())
      .then((data) => setVersion(data.version))
      .catch((error) => console.error('Error fetching version:', error));
  }, []);

  const { t } = useTranslation();
  return (
    <Wrapper>
      <FooterContainer>
        <Row gutter={[16, 16]} className={'mb-12 lg:mb-0'}>
          <Col>
            <div>
              © {new Date().getFullYear()}
              <Anchor href="http://najmuje.com/" target={'_blank'}>
                najmuje.com
              </Anchor>
            </div>
            <div>
              <a href="mailto:kontakt@najmuje.com" target={'_blank'} rel="noreferrer">
                kontakt@najmuje.com
              </a>
            </div>
          </Col>
        </Row>

        <RightSide>
          <Row gutter={[16, 16]}>
            <Col className="flex flex-col">
              <Anchor href={`https://web.najmuje.com`}>{t('ABOUT_US')}</Anchor>
              <Anchor href={`/pomoc`}>{t('WORKSPACE_MENU.SUPPORT')}</Anchor>
              <Anchor href={`/faq`}>FAQ</Anchor>
            </Col>{' '}
            <Col className="flex flex-col">
              <Anchor
                onClick={() =>
                  window.open(AppRoutes.DOCUMENT.replace(':documentPath', 'documents&FL_AspektyPrawne.pdf'), '_blank')
                }>
                {t('LEGAL_ASPECTS')}
              </Anchor>
              <Anchor
                onClick={() =>
                  window.open(
                    AppRoutes.DOCUMENT.replace(':documentPath', 'documents&FL_PolitykaPrywatnosci.pdf'),
                    '_blank'
                  )
                }>
                {t('PRIVACY_POLICY')}
              </Anchor>
              <Anchor
                onClick={() =>
                  window.open(AppRoutes.DOCUMENT.replace(':documentPath', 'documents&FL_Regulamin.pdf'), '_blank')
                }>
                {t('TERMS_AND_CONDITIONS')}
              </Anchor>
              <Anchor
                onClick={() =>
                  window.open(
                    AppRoutes.DOCUMENT.replace(':documentPath', 'documents&FL_Regulamin-Wynajmujacy.pdf'),
                    '_blank'
                  )
                }>
                {t('TERMS_AND_CONDITIONS_LANDLORD')}
              </Anchor>
              <Anchor
                onClick={() =>
                  window.open(
                    AppRoutes.DOCUMENT.replace(':documentPath', 'documents&FL_Regulamin-Najemca.pdf'),
                    '_blank'
                  )
                }>
                {t('TERMS_AND_CONDITIONS_TENANT')}
              </Anchor>
              <Anchor
                onClick={() =>
                  window.open(AppRoutes.DOCUMENT.replace(':documentPath', 'documents&FL_Cennik.pdf'), '_blank')
                }>
                {t('PRICE_LIST')}
              </Anchor>
            </Col>
          </Row>
        </RightSide>
      </FooterContainer>

      <Paragraph className="text-white text-center opacity-50 mt-12 lg:mt-0">
        <span>{t('ALL_RIGHTS_RESERVED')}</span>
        <div className="opacity-75">
          {t('VERSION')} {version}
        </div>
      </Paragraph>
    </Wrapper>
  );
};
