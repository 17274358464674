import { Button, Card, Input } from 'antd';
import * as React from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router';

import { firebaseFunctions, firestore } from '../../../firebase/firebase';
import { FirebaseCallableFunctions, FirestoreCollection } from '../../../firebase/firebase.models';
import { Verification } from '../../../models/offers.model';
import { AppRoutes } from '../../Routing/routing.model';
interface Props {
  offerId: string;
}

export const ConnectOfferToUser: React.FC<Props> = ({ offerId }: { offerId: string }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<string>('');
  const { push } = useHistory();
  const handleSubmit = async () => {
    setLoading(true);
    const user = await getUserByEmail(email);
    if (user) {
      firestore.collection(FirestoreCollection.OFFERS).doc(offerId).update({ uid: user.uid, landlord: user.email });
      // trzeba sprawdzić czy uytkownik jest podłączony do firmy i jeśli tak to dodać do niej ofertę
      if (user.verification?.company === Verification.ACCEPTED && user.companyId) {
        const company = (await firestore.collection(FirestoreCollection.COMPANIES).doc(user.companyId).get()).data();
        firestore
          .collection(FirestoreCollection.COMPANIES)
          .doc(user.companyId)
          .update({ offers: [...company.offers, offerId] });
      } else {
        // jesli nie to zrobić to co poniej
        const userOffers = user.offers || [];
        firestore
          .collection(FirestoreCollection.USERS)
          .doc(user.uid)
          .update({ offers: [...userOffers, offerId] });
      }
      setEmail('');
      try {
        // call cloud function to send email
        const resp = await firebaseFunctions.httpsCallable(FirebaseCallableFunctions.LINK_PROJECT_OFFER)({
          email: email,
          offerId: offerId,
        });
        setLoading(false);
        console.log(resp);
        alert(resp.data.message);
        push(AppRoutes.MOJA_TABLICA_UNSIGNED_OFFERS);
      } catch (error) {
        console.log(error);
        setLoading(false);
        setError('Error connecting offer to user');
      }
    } else {
      setError('User not found');
    }
  };
  return (
    <Card className="w-96 space-y-1 mt-12" title="Connect offer to user">
      <Input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Enter email" />
      <Button onClick={handleSubmit} className="mt-8" loading={loading}>
        Connect
      </Button>
      {error && <div className="text-red-500">{error}</div>}
    </Card>
  );
};
async function getUserByEmail(email: string) {
  const querySnapshot = await firestore.collection(FirestoreCollection.USERS).where('email', '==', email).get();

  if (!querySnapshot.empty) {
    return querySnapshot.docs[0].data();
  } else {
    return null;
  }
}
