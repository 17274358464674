import { EyeOutlined } from '@ant-design/icons';
import { List, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuth } from '../../../features/Auth/AuthContext';
import { useDocumentModal } from '../../../features/Documents/DocumentModalProvider';
import { firebaseStorage, firestore } from '../../../firebase/firebase';
import { FirestoreCollection } from '../../../firebase/firebase.models';
import { Offer } from '../../../models/offers.model';

interface Props {
  isModalOpen: boolean;
  toggleModal: () => void;
  offer: Offer;
}

export const DocumentsOfferModal: React.FC<Props> = ({ isModalOpen, toggleModal, offer }) => {
  const { currentUser } = useAuth();
  const [pdfFiles, setPdfFiles] = useState<string[]>([]);
  const [companyId, setCompanyId] = useState<string | null>(null);
  const { showDocumentModal } = useDocumentModal();
  const { t } = useTranslation();

  const getCompany = async () => {
    if (!currentUser?.uid) return;
    const userCompanyId = await firestore
      .collection(FirestoreCollection.USERS)
      .doc(currentUser?.uid)
      .get()
      .then((doc) => doc.data()?.companyId);
    return userCompanyId;
  };

  useEffect(() => {
    getCompany().then((companyId) => {
      if (companyId) {
        setCompanyId(companyId);
      }
    });
  }, [currentUser?.uid]);

  const fetchPdfFiles = async () => {
    const folderRef = firebaseStorage.ref(`companies/${companyId}/offers/${offer.id}`);
    const result = await folderRef.listAll();
    const pdfNames = result.items.filter((item) => item.name.endsWith('.pdf')).map((item) => item.name);

    setPdfFiles(pdfNames);
  };

  useEffect(() => {
    if (isModalOpen) {
      fetchPdfFiles();
    }
  }, [isModalOpen, offer.id]);

  const handleShowFile = async (fileName: string) => {
    const fileRef = firebaseStorage.ref(`companies/${companyId}/offers/${offer.id}/${fileName}`);
    const downloadUrl = await fileRef.getDownloadURL();
    showDocumentModal(downloadUrl);
  };

  return (
    <Modal
      title={t('OFFER.OFFER_DOCUMENTS_HEADING')}
      open={isModalOpen}
      onOk={toggleModal}
      onCancel={toggleModal}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}>
      <List
        bordered
        dataSource={pdfFiles}
        renderItem={(item) => (
          <List.Item style={{ display: 'flex', justifyContent: 'space-between' }}>
            <>
              <span>{item}</span>
              <div>
                <a onClick={() => handleShowFile(item)}>
                  {
                    <EyeOutlined className="text-xl opacity-50 hover:opacity-70 hover:scale-110 transition-transform duration-300" />
                  }
                </a>
              </div>
            </>
          </List.Item>
        )}
      />
    </Modal>
  );
};
