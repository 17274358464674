import { Button, Form, Input, InputNumber, List, Select, Space, Typography } from 'antd';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next'; // Hook tłumaczeń

import { SelectOption } from '../../../models/main.model';
import { LocalMeterReadings } from '../../../models/protocol.model';

import { mergeArrays } from './mergeArrays';

interface Props {
  onChange: (data: LocalMeterReadings[]) => void;
  initialValues: LocalMeterReadings[];
}

const initial: LocalMeterReadings[] = [
  {
    name: 'ELECTRICITY',
    meterNumber: null,
    quantity: 0,
    unit: 'kWh',
  },
  {
    name: 'GAS',
    meterNumber: null,
    quantity: 0,
    unit: 'm3',
  },
  {
    name: 'HOT_WATER',
    meterNumber: null,
    quantity: 0,
    unit: 'l',
  },
  {
    name: 'COLD_WATER',
    meterNumber: null,
    quantity: 0,
    unit: 'l',
  },
  {
    name: 'HEAT',
    meterNumber: null,
    quantity: 0,
    unit: 'm3',
  },
];

export function useUnitType(): SelectOption[] {
  return useMemo(
    () =>
      ['kWh', 'm³', 'l', 'GJ'].map((item) => ({
        label: item,
        value: item,
      })),
    []
  );
}

const LocalMeterReadingsComponent: React.FC<Props> = ({ onChange, initialValues }) => {
  const { t } = useTranslation(); // Hook tłumaczeń
  const mergedInitialData = useMemo(() => mergeArrays(initial, initialValues), [initialValues]);
  const [localMeterReadings, setLocalMeterReadings] = useState(mergedInitialData);
  const [newElement, setNewElement] = useState('');
  const [newMeterNumber, setNewMeterNumber] = useState(null);
  const [newValueOfNewElement, setNewValueOfNewElement] = useState(0);
  const [newUnit, setNewUnit] = useState(null);
  const unitType = useUnitType();

  const handleAdd = () => {
    const newItem = { name: newElement, meterNumber: newMeterNumber, quantity: newValueOfNewElement, unit: newUnit };
    setLocalMeterReadings([...localMeterReadings, newItem]);
    onChange([...localMeterReadings, newItem]);
    setNewElement('');
    setNewMeterNumber(null);
    setNewValueOfNewElement(0);
    setNewUnit(null);
  };

  const handleUnitChange = (index, newUnit) => {
    const updatedList = [...localMeterReadings];
    updatedList[index].unit = newUnit;
    setLocalMeterReadings(updatedList);
    onChange(updatedList);
  };

  const handleQuantityChange = (index, value) => {
    const updatedList = [...localMeterReadings];
    updatedList[index].quantity = value;
    setLocalMeterReadings(updatedList);
    onChange(updatedList);
  };

  const handleMeterNumberChange = (index, value) => {
    const updatedList = [...localMeterReadings];
    updatedList[index].meterNumber = value;
    setLocalMeterReadings(updatedList);
    onChange(updatedList);
  };

  const LocalMeterReadingsDisplay = ({ item }: { item: LocalMeterReadings }) => {
    if (!item) return null;
    // Znajdź indeks elementu w initial
    const index = initial.findIndex((meter: LocalMeterReadings) => meter.name === item.name);

    return (
      <div>
        {/* Jeśli element jest w initial, wyświetl tłumaczenie */}
        {index !== -1 ? (
          <Typography.Text className="mr-4">{t(`PROTOCOL.C.METER_TYPES.${item.name}`)}</Typography.Text>
        ) : (
          // Jeśli element nie jest w initial, wyświetl dynamiczną nazwę bez przedrostka
          <Typography.Text className="mr-4">{item.name}</Typography.Text>
        )}
      </div>
    );
  };

  return (
    <div className="mt-2">
      <List
        header={<div className="font-medium text-base">{t('PROTOCOL.C.TITLE')}</div>}
        bordered
        dataSource={localMeterReadings}
        renderItem={(item, index) => (
          <>
            <List.Item className="flex items-center">
              <Space>
                <LocalMeterReadingsDisplay key={item.name} item={item} />
              </Space>
              <Space className="mt-2 mb-2 flex ml-auto">
                <Form.Item className="-mb-1 mr-2">
                  <InputNumber
                    min={0}
                    className="w-32"
                    value={item.meterNumber}
                    onChange={(value) => handleMeterNumberChange(index, value)}
                    placeholder={t('PROTOCOL.C.METER_NUMBER')}
                  />
                </Form.Item>
                <Form.Item className="-mb-1 mr-2">
                  <InputNumber
                    min={0}
                    className="w-14"
                    value={item.quantity}
                    onChange={(value) => handleQuantityChange(index, value)}
                  />
                </Form.Item>
                <Form.Item className="-mb-1 w-20">
                  <Select
                    options={unitType}
                    defaultValue={item.unit}
                    onChange={(value) => handleUnitChange(index, value)}
                    placeholder={t('PROTOCOL.C.UNIT')}
                  />
                </Form.Item>
              </Space>
            </List.Item>
            {index + 1 === localMeterReadings.length && (
              <List.Item>
                <Space className="table sm:flex ">
                  <Typography.Text>{index + 2}</Typography.Text>
                  <Input
                    value={newElement}
                    onChange={(e) => setNewElement(e.target.value)}
                    className="w-auto ml-4"
                    placeholder={t('PROTOCOL.C.METER')}
                  />
                  <Input
                    value={newMeterNumber}
                    onChange={(e) => setNewMeterNumber(e.target.value)}
                    className="w-auto float-left mt-2 sm:mt-0 ml-4"
                    placeholder={t('PROTOCOL.C.METER_NUMBER')}
                  />
                  <InputNumber
                    min={0}
                    className="w-14 mt-2 sm:mt-0 ml-4"
                    value={newValueOfNewElement}
                    onChange={(value) => setNewValueOfNewElement(value)}
                    placeholder={t('PROTOCOL.C.QUANTITY')}
                  />
                  <Select
                    options={unitType}
                    defaultValue={newUnit}
                    className="min-w-20 mt-2 sm:mt-0 ml-4"
                    onChange={setNewUnit}
                    placeholder={t('PROTOCOL.C.UNIT')}
                  />
                </Space>
                <Space className="mt-2 mb-2 flex ml-auto">
                  <Button onClick={handleAdd} type="default" className="float-right w-24">
                    {t('ADD')}
                  </Button>
                </Space>
              </List.Item>
            )}
          </>
        )}
      />
    </div>
  );
};

export default LocalMeterReadingsComponent;
