import { GeocodeSearchItem } from '../../../components/LeafletMap/leaflet.model';
import { OfferAddressDetails, OfficialAddress } from '../../../models/offers.model';

export const getFormattedAddress = (
  searchResult: GeocodeSearchItem,
  plotNumber: string,
  officialAddress: OfficialAddress,
  ownershipType: string
): OfferAddressDetails => {
  const street =
    searchResult && searchResult.properties
      ? searchResult.properties.StName + ' ' + searchResult.properties.AddNum
      : '';
  const postCode = searchResult && searchResult.properties ? searchResult.properties.Postal : '';
  const district = searchResult && searchResult.properties ? searchResult.properties.District : '';
  const city = searchResult && searchResult.properties ? searchResult.properties.StName : '';
  const country = searchResult && searchResult.properties ? searchResult.properties.Country : '';
  const province = searchResult && searchResult.properties ? searchResult.properties.Region : '';
  const inputText = searchResult.text;

  return {
    city,
    postCode,
    district,
    street,
    country,
    province,
    plotNumber,
    inputText,
    officialAddress,
    ownershipType,
  };
};
