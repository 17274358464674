import { Carousel, Divider, Modal } from 'antd';
import * as React from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { PanelContent, PanelWhite, WidthWrapper } from '../../components/Layout/styles';
import { useGetCompanyData } from '../../firebase/hooks/getCompanyData.hook';
import { useGetTenant } from '../../firebase/hooks/getTenant.hook';
import { UserVerified } from '../../models/main.model';
import { Offer, OfferStatusState } from '../../models/offers.model';
import { hashValue } from '../../utils/hash.utils';
import { App, SHOW_LOGS } from '../App/App';
import { useAuth } from '../Auth/AuthContext';
import { FormFooterWrapper } from '../OfferAdd/components/OfferForm';

import { ContinueEditing } from './components/ContinueEditing';
import { CounterOffer } from './components/CounterOffer';
import { Description } from './components/Description';
import { DetailsAndFunctions } from './components/DetailsAndFunctions';
import { GoFurther } from './components/GoFurther';
import { Heading } from './components/Heading';
import { Message } from './components/Message';
import { OfferLocation } from './components/OfferLocation';
import { OfferMainButtons } from './components/OfferMainButtons';
import { OfferOptions } from './components/OfferOptions';
import { OfferValidCountingDown } from './components/OfferValidCountingDown';
import { ProgessSteps } from './components/ProgressSteps';
import { SignButton } from './components/SignButton';
import { SignInToShow } from './components/SignInToShow';
import { isUserVerified } from './hooks/isUserVerified.hook';
import { useGetLeasements } from './hooks/useGetLeasements.hook';

const Wrapper = styled.div`
  background-color: #f3f7fd;
`;

const MainGrid = styled.section<{ preview: boolean }>`
  display: grid;
  grid-template-columns: ${({ preview }) => (preview ? `1fr` : `1fr 416px`)};
  grid-gap: ${({ theme }) => theme.gap(4)};
  padding-bottom: ${({ theme }) => theme.gap(10)};
  align-items: self-start;

  @media (max-width: 768px) {
    grid-template-columns: 1fr; /* Jeden element w kolumnie */
  }
`;

const GridColumn = styled.section`
  display: flex;
  flex-flow: column;

  & > * {
    margin-bottom: 32px;
  }

  @media (max-width: 768px) {
    & > * {
      margin-bottom: 8px; /* Mniejsze odstępy na mobilnych */
    }
  }
`;

interface Props {
  offer: Offer;
  images: string[];

  preview?: boolean;
}

export const OfferView: React.FC<Props> = ({ offer, images, preview }) => {
  const [offerExpanded, setOfferExpanded] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const offerOptionsRef = useRef<HTMLDivElement>();
  const { currentUser, isAuth } = useAuth();
  const [tenant] = useGetTenant(currentUser?.uid);
  const userVerified: UserVerified = isUserVerified(currentUser);
  const leaseAgreements = useGetLeasements(offer.id, offer.leaseAgreement);
  const { t } = useTranslation();
  const company = useGetCompanyData(currentUser?.uid);
  const isMobile = window.innerWidth < 768;

  const onGoToOfferOptionsClick = useCallback(() => {
    setOfferExpanded(true);
    setTimeout(() => offerOptionsRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' }), 500);
  }, []);

  const _images = useMemo(() => {
    if (!images?.length) return null;
    if (images.length > 1) return images;
    if (images.length === 1) return [images[0], images[0], images[0]];
  }, [images]);

  // Sprawdzenie czy użytkownik jest zalogowany

  SHOW_LOGS && console.log(currentUser);

  const isOwnOffer = hashValue(company?.companyData?.id) === offer.offerStatus?.landlord?.companyId;

  return (
    <App>
      <Wrapper>
        <ImageSlider images={_images ?? []} offer={undefined} />

        <WidthWrapper>
          <Heading offer={offer} offerImage={images?.[0]} />

          <MainGrid preview={preview}>
            <GridColumn>
              <DetailsAndFunctions offer={offer} defaultExpanded={preview} isUserLoggedIn={isAuth} />
              {!preview &&
                offer.offerStatus.state === OfferStatusState.ACTIVE &&
                (isAuth ? (
                  <OfferOptions
                    expanded={offerExpanded}
                    setExpanded={setOfferExpanded}
                    offerOptionsRef={offerOptionsRef}
                    tenant={tenant}
                    offerId={offer.id}
                    userVerified={userVerified}
                    leaseAgreements={leaseAgreements}
                    unitReceiptDeadline={offer.unitReceiptDeadline}
                    leaseAgreement={offer.leaseAgreement}
                    offer={offer}
                    disabledSigning={!isOwnOffer}
                  />
                ) : (
                  <PanelWhite bodyFill>
                    <PanelContent>
                      <SignInToShow text={t('SIGN_IN_TO_SHOW.RENTAL_AGREEMENTS')} rows={5} />
                    </PanelContent>
                  </PanelWhite>
                ))}

              {offer.offerStatus.state === OfferStatusState.BOOKED &&
                offer.offerStatus.tenant.email === currentUser.email &&
                (isAuth ? (
                  <OfferOptions
                    expanded={offerExpanded}
                    setExpanded={setOfferExpanded}
                    offerOptionsRef={offerOptionsRef}
                    tenant={tenant}
                    offerId={offer.id}
                    userVerified={userVerified}
                    leaseAgreements={leaseAgreements}
                    unitReceiptDeadline={offer.unitReceiptDeadline}
                    leaseAgreement={offer.leaseAgreement}
                    offer={offer}
                    disabledSigning={currentUser.uid !== offer.uid}
                  />
                ) : (
                  <></>
                ))}
            </GridColumn>
            {isMobile && <Divider className="p-0 m-0" />}
            {!preview && (
              <GridColumn>
                <div className="p-2 space-y-4">
                  {isAuth ? (
                    <OfferValidCountingDown offer={offer}></OfferValidCountingDown>
                  ) : (
                    <PanelWhite bodyFill>
                      <PanelContent>
                        <SignInToShow text={t('SIGN_IN_TO_SHOW.OFFER_EXPIRATION')} />
                      </PanelContent>
                    </PanelWhite>
                  )}
                  {offer.description && <Description description={offer.description} />}
                  {offer.geoMarker && <OfferLocation offer={offer} />}
                  {offer.offerStatus.state === OfferStatusState.ACTIVE && (
                    <GoFurther onGoToOfferSection={onGoToOfferOptionsClick} />
                  )}
                  {!isAuth && offer.counteroffer.selected && currentUser?.uid !== offer.uid && (
                    <PanelWhite bodyFill>
                      <PanelContent>
                        <SignInToShow text={t('SIGN_IN_TO_SHOW.COUNTEROFFER_DETAILS')} />
                      </PanelContent>
                    </PanelWhite>
                  )}
                  {isAuth && offer.counteroffer.selected && currentUser.uid !== offer.uid && (
                    <CounterOffer
                      tenant={currentUser.email}
                      offerId={offer.id}
                      price={offer.price}
                      landlord={offer.landlord}
                    />
                  )}
                  {isAuth ? (
                    <>
                      <OfferMainButtons id={offer.id} />
                      <Message currentUser={currentUser} offer={offer} disabled={currentUser.uid === offer.uid} />
                    </>
                  ) : (
                    <PanelWhite bodyFill>
                      <PanelContent>
                        <SignInToShow text={t('SIGN_IN_TO_SHOW.ASK_ABOUT_OFFER')} rows={4} />
                      </PanelContent>
                    </PanelWhite>
                  )}
                </div>
              </GridColumn>
            )}
            {offer.offerStatus.state === OfferStatusState.UNSIGNED && isAuth && (
              <>
                <ProgessSteps offer={offer} currentUser={currentUser} loading={loading} setLoading={setLoading} />
                <FormFooterWrapper>
                  <ContinueEditing offerId={offer.id} />
                  <SignButton offer={offer} currentUser={currentUser} loading={loading} setLoading={setLoading} />
                </FormFooterWrapper>
              </>
            )}
          </MainGrid>
        </WidthWrapper>
      </Wrapper>
    </App>
  );
};

const StyledCarousel = styled(Carousel)`
  .slick-slide img {
    max-height: 300px;
    object-fit: cover;
    width: 100%;
    cursor: pointer;
  }
`;

const StyledModalImage = styled.img`
  width: 100%;
  height: auto;
`;

interface Props {
  images: string[];
}

export const ImageSlider: React.FC<Props> = ({ images }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [slidesToShow, setSlidesToShow] = useState(1);

  useEffect(() => {
    const updateSlidesToShow = () => {
      const width = window.innerWidth;

      if (images.length <= 2) {
        setSlidesToShow(1);
      } else if (width > 1200) {
        setSlidesToShow(3); // 3 zdjęcia na szerokich ekranach
      } else if (width > 768) {
        setSlidesToShow(2); // 2 zdjęcia na tabletach
      } else {
        setSlidesToShow(1); // 1 zdjęcie na urządzeniach mobilnych
      }
    };

    updateSlidesToShow();
    window.addEventListener('resize', updateSlidesToShow);
    return () => window.removeEventListener('resize', updateSlidesToShow);
  }, [images.length]);

  const handleImageClick = (image: string) => {
    setSelectedImage(image);
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
    setSelectedImage(null);
  };

  return (
    <>
      {/* Slider */}
      <StyledCarousel slidesToShow={slidesToShow} arrows>
        {images.map((image, index) => (
          <div key={index}>
            <img
              src={image}
              alt={`Slide ${index}`}
              onClick={() => handleImageClick(image)} // Powiększenie obrazu po kliknięciu
            />
          </div>
        ))}
      </StyledCarousel>

      {/* Modal z powiększonym zdjęciem */}
      <Modal open={isModalVisible} footer={null} onCancel={handleCloseModal} centered width="80%">
        {selectedImage && <StyledModalImage src={selectedImage} alt="Selected" />}
      </Modal>
    </>
  );
};
