import { Alert, Popover } from 'antd';
import { useField } from 'formik';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  ButtonGroup,
  CheckPicker,
  DatePicker,
  Input,
  InputNumber,
  InputPicker,
  Panel,
  PanelGroup,
  SelectPicker,
  Toggle,
  TreePicker,
} from 'rsuite';
import styled from 'styled-components';

import { FormInput } from '../../../components/FormInputs/FormInput';
import { FormToggle } from '../../../components/FormInputs/FormToggle';
import { Typography } from '../../../components/Typography/Typography';
import {
  OfferCommercialAndServiceRoomDetails,
  OfferLevelDetails,
  OfferOfficeDetails,
  OfferTechnicalRoomDetails,
  OfferWarehouseDetails,
} from '../../../models/offers.model';
import { LocalizedLabelWithMultipleModals } from '../utils/LocalizedLabelWithMultipleModals';

import { useBuildingType } from './FormOptions/useBuildingType.hook';
import { useCommercialAndServiceRoomTypeOptions } from './FormOptions/useCommercialAndServiceRoomTypeOptions.hook';
import { useDriveType } from './FormOptions/useDriveType.hook';
import { useEnergyCategory } from './FormOptions/useEnergyCategoryOptions.hook';
import { useIsulationType } from './FormOptions/useIsulationType.hook';
import { useMaterialType } from './FormOptions/useMaterialType.hook';
import { useNonRentFees } from './FormOptions/useNonRentFees.hook';
import { useOfficeEquipment } from './FormOptions/useOfficeEquipment.hook';
import { useOfficeTypeOptions } from './FormOptions/useOfficeTypeOptions.hook';
import { useOtherRoomsOptions } from './FormOptions/useOtherRoomsOptions.hhok';
import { useOwnershipTypeOptions } from './FormOptions/useOwnershipTypeOptions';
import { usePremisesPurposeWarehouseSelectOptions } from './FormOptions/usePremisesPurposeWarehouseSelectOptions.hook';
import { useSourrundingType } from './FormOptions/useSourrundingType.hook';
import { useTechnicalRoomTypeOptions } from './FormOptions/useTechnicalRoomTypeOptions.hook';
import { useWarehouseEquipment } from './FormOptions/useWarehouseEquipment.hook';
import { useWarehouseRoomTypeOptions } from './FormOptions/useWarehouseRoomTypeOptions.hook';
import { BasicInputs } from './OfferForm';

export const InputsBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  & > * {
    margin-right: 10px;
    flex-grow: 1;
    flex-basis: 0;
  }
`;

export const RowBox = styled.div`
  display: flex;
  flex-direction: row;
  & > *:not(:last-child) {
    margin-right: 8px;
  }
`;

const RowBoxWithMargin = styled(RowBox)`
  & > * {
    margin-left: 76px;
  }

  & > :first-child {
    margin-left: 0;
  }
`;

const overlay = {
  width: '80%',
  maxWidth: '350px',
};

interface Props {
  children?: React.ReactNode;
  legalMainUse: string;
  surface: number;
  setSaved?: React.Dispatch<React.SetStateAction<boolean>>;
  submitted: boolean;
}

export const OfferInputs: React.FC<Props> = ({ children, legalMainUse, surface, setSaved, submitted }, props) => {
  const [prop, , helpers] = useField(props);
  const { t } = useTranslation();
  const [sumSurface, setSumSurface] = useState<number>(0);
  const [floorSurfaces, setFloorSurfaces] = useState<number[]>([]);
  const [selectedFeeType, setSelectedFeeType] = useState(null);
  const [selectedInfinite, setSelectedInfinite] = useState(null);
  const [selectedFixedshort, setSelectedFixedshort] = useState(null);
  const [selectedFixedlong, setSelectedFixedlong] = useState(null);
  const [selectedOtherRooms, setSelectedOtherRooms] = useState([]);
  const [selectedOwnTerms, setSelectedOwnTerms] = useState(null);
  const [showSubleaseAgreement, setShowSubleaseAgreement] = useState(false);

  const premisesPurposeWarehouseSelectOptions = usePremisesPurposeWarehouseSelectOptions();
  const officeTypeOptions = useOfficeTypeOptions();
  const warehouseRoomTypeOptions = useWarehouseRoomTypeOptions();
  const technicalRoomTypeOptions = useTechnicalRoomTypeOptions();
  const commercialAndServiceRoomTypeOptions = useCommercialAndServiceRoomTypeOptions();
  const buildingType = useBuildingType();
  const energyCategoryOptions = useEnergyCategory();
  const materialType = useMaterialType();
  const driveType = useDriveType();
  const isulationType = useIsulationType();
  const sourrundingType = useSourrundingType();
  const warehouseEquipment = useWarehouseEquipment();
  const officeEquipment = useOfficeEquipment();
  const nonRentFeesOptions = useNonRentFees();
  const otherRoomsOptions = useOtherRoomsOptions();
  const ownershipTypeOptions = useOwnershipTypeOptions();

  const handleSurfaces = useCallback(() => {
    let sum = 0;
    prop.value.areaDetails.levels.forEach((level) => {
      ['offices', 'warehouses', 'technicalRooms', 'commercialAndServiceRooms'].forEach((roomType) => {
        level[roomType]?.forEach((room) => {
          const surface = parseFloat(room.surface);
          if (!isNaN(surface)) {
            sum += surface;
          }
        });
      });
    });
    setSumSurface(sum);
  }, [prop.value.areaDetails.levels]);

  useEffect(() => {
    handleSurfaces();
  }, [handleSurfaces, surface, sumSurface, prop.value.areaDetails.levels]);

  const handleFloorSurfaces = useCallback(() => {
    const newFloorSurfaces = [];

    prop.value.areaDetails.levels.forEach((level, i) => {
      let floorSum = null;
      ['offices', 'warehouses', 'technicalRooms', 'commercialAndServiceRooms'].forEach((roomType) => {
        level[roomType]?.forEach((room) => {
          const surface = parseFloat(room.surface);
          if (!isNaN(surface)) {
            floorSum += surface;
          }
        });
      });
      newFloorSurfaces[i] = floorSum;
    });

    setFloorSurfaces(newFloorSurfaces);
  }, [prop.value.areaDetails.levels]);

  useEffect(() => {
    if (prop.value.address.ownershipType === 'Umowa najmu') setShowSubleaseAgreement(true);
    else setShowSubleaseAgreement(false);
  }, [prop.value.address.ownershipType]);

  useEffect(() => {
    handleSurfaces();
    handleFloorSurfaces();
  }, [handleSurfaces, handleFloorSurfaces, surface, sumSurface, prop.value.areaDetails.levels]);

  const onAddAreaDetails = useCallback(
    (array, levelNumber = null) => {
      setSaved(false);
      let newValue;
      if (levelNumber === null) {
        newValue = array.push({});
      } else {
        if (!prop.value.areaDetails.levels[levelNumber][array]) {
          prop.value.areaDetails.levels[levelNumber][array] = [];
        }
        newValue = prop.value.areaDetails.levels[levelNumber][array].push({});
      }
      helpers.setValue(newValue);
    },
    [prop.value]
  );

  const onRemoveAreaDetails = useCallback(
    (array, levelNumber = null) => {
      setSaved(false);
      let newValue;
      if (levelNumber === null) {
        newValue = array.pop();
      } else {
        newValue = prop.value.areaDetails.levels[levelNumber][array].pop();
      }
      helpers.setValue(newValue);
      handleSurfaces();
    },
    [prop.value]
  );

  const onAddGate = useCallback(() => {
    setSaved(false);
    if (!prop.value.relatedDetails.gates) {
      prop.value.relatedDetails.gates = [];
    }
    const newValue = prop.value.relatedDetails.gates.push({});
    helpers.setValue(newValue);
  }, [prop.value.relatedDetails]);

  const onRemoveGate = useCallback(() => {
    setSaved(false);
    const newValue = prop.value.relatedDetails.gates.pop();
    helpers.setValue(newValue);
  }, [prop.value]);

  useEffect(() => {
    setSaved(false);
    if (prop.value.leaseAgreement.nonRentFees == 'Ryczałtowe') setSelectedFeeType('Ryczałtowe');
    else if (prop.value.leaseAgreement.nonRentFees == 'Licznikowe') setSelectedFeeType('Licznikowe');
    else setSelectedFeeType(false);
  }, [prop.value.leaseAgreement.nonRentFees]);

  useEffect(() => {
    setSaved(false);
    if (prop.value.leaseAgreement.infinite) setSelectedInfinite(true);
    else setSelectedInfinite(false);
  }, [prop.value.leaseAgreement.infinite, prop.value.leaseAgreement.infinite]);

  useEffect(() => {
    setSaved(false);
    if (prop.value.leaseAgreement.fixedshort) setSelectedFixedshort(true);
    else setSelectedFixedshort(false);
  }, [prop.value.leaseAgreement.fixedshort, prop.value.leaseAgreement.fixedshortMonths]);

  useEffect(() => {
    setSaved(false);
    if (prop.value.leaseAgreement.fixedlong) setSelectedFixedlong(true);
    else setSelectedFixedlong(false);
  }, [prop.value.leaseAgreement.fixedlong, prop.value.leaseAgreement.fixedlongMonths]);

  useEffect(() => {
    setSaved(false);
    const newSelectedOtherRooms = prop.value.areaDetails.levels.map((level: OfferLevelDetails) => level.otherRooms);
    setSelectedOtherRooms(newSelectedOtherRooms);
  }, [prop.value.areaDetails.levels]);

  useEffect(() => {
    setSaved(false);
    if (prop.value.ownTerms.selected) setSelectedOwnTerms(true);
    else setSelectedOwnTerms(false);
  }, [prop.value.ownTerms.selected]);

  return (
    <PanelGroup>
      <Panel header={t('FORMS.OFFER_ADD.NAMES.PURPOSE')} defaultExpanded collapsible>
        <InputsBox>
          <Popover
            trigger="hover"
            placement="topLeft"
            overlayStyle={overlay}
            content={<div className="font-semibold">{t('FORMS.OFFER_ADD.POPOVERS.TENANT_AGREEMENT')}</div>}>
            <FormInput
              submitted={submitted}
              label={t('FORMS.OFFER_ADD.NAMES.PURPOSE_ACCEPTABLE')}
              name={'legalWarehouseUseAccepted'}
              inputAccepter={CheckPicker}
              data={premisesPurposeWarehouseSelectOptions}
            />
          </Popover>
          <Popover
            trigger="hover"
            placement="topLeft"
            overlayStyle={overlay}
            content={<div className="font-semibold">{t('FORMS.OFFER_ADD.POPOVERS.USAGE_PERMISSION')}</div>}>
            <FormInput
              submitted={submitted}
              label={t('FORMS.OFFER_ADD.NAMES.PURPOSE_COMPATIBLE')}
              name={'legalWarehouseUseOfficial'}
              inputAccepter={CheckPicker}
              data={premisesPurposeWarehouseSelectOptions}
            />
          </Popover>

          <FormInput submitted={submitted} label={t('FORMS.OFFER_ADD.NAMES.PLOT_NUMBER')} name={'address.plotNumber'} />
          <FormInput
            submitted={submitted}
            label={t('FORMS.OFFER_ADD.NAMES.LAND_AND_MORTGAGE_REGISTER_NUMBER')}
            name={'landAndMortgageRegisterNumber'}
          />
          <FormInput
            submitted={submitted}
            label="Rodzaj własności"
            name={'address.ownershipType'}
            inputAccepter={InputPicker}
            data={ownershipTypeOptions}
          />
          {showSubleaseAgreement && (
            <>
              <div className="my-auto">
                <FormToggle
                  submitted={submitted}
                  label={'Zgoda na podnajem'}
                  name={'subleaseAgreement'}
                  className="w-auto"
                  checked={prop.value.subleaseAgreement}
                />
              </div>
              {/* puste div-y dla zachowania układu */}
              <div> </div>
              <div> </div>
            </>
          )}
        </InputsBox>
      </Panel>
      <Panel header={'Budynek'} defaultExpanded collapsible>
        <InputsBox>
          <FormInput
            submitted={submitted}
            label={t('FORMS.OFFER_ADD.NAMES.BUILDING_TYPE')}
            name={'areaDetails.buildingType'}
            inputAccepter={TreePicker}
            defaultExpandAll
            data={buildingType}
          />
          <FormInput
            submitted={submitted}
            label={t('FORMS.OFFER_ADD.NAMES.CONSTRUCTION_MATERIAL')}
            name={'areaDetails.constructionMaterial'}
            inputAccepter={CheckPicker}
            data={materialType}
          />
          <FormInput
            submitted={submitted}
            label={t('FORMS.OFFER_ADD.NAMES.THERMAL_INSULATION')}
            name={'areaDetails.thermalInsulation'}
            inputAccepter={CheckPicker}
            data={isulationType}
          />
          <FormInput
            submitted={submitted}
            label={t('FORMS.OFFER_ADD.NAMES.BUILDING_COMPLETION_YEAR')}
            name={'areaDetails.constructionCompletionYear'}
            inputAccepter={InputNumber}
            step={1}
          />
          <Popover
            trigger="hover"
            placement="topLeft"
            overlayStyle={overlay}
            content={<div className="font-semibold">{t('FORMS.OFFER_ADD.POPOVERS.BUILDING_LAND')}</div>}>
            <FormInput
              submitted={submitted}
              label={t('FORMS.OFFER_ADD.NAMES.LAND_PLOT_AREA')}
              name={'areaDetails.landArea'}
              inputAccepter={InputNumber}
              postfix="m&sup2;"
              min={1}
              step={100}
            />
          </Popover>

          <FormInput
            submitted={submitted}
            label={t('FORMS.OFFER_ADD.NAMES.ACCESS')}
            name={'areaDetails.drive'}
            inputAccepter={CheckPicker}
            data={driveType}
          />
          <FormInput
            submitted={submitted}
            label={t('FORMS.OFFER_ADD.NAMES.DISTANCE_TO_PUBLIC_TRANSPORT')}
            name={'areaDetails.publicTransportDistance'}
            inputAccepter={InputNumber}
            postfix="m"
            min={0}
            step={1}
          />
          <FormInput
            submitted={submitted}
            label={t('FORMS.OFFER_ADD.NAMES.SURROUNDINGS')}
            name={'areaDetails.surroundings'}
            inputAccepter={CheckPicker}
            data={sourrundingType}
          />
        </InputsBox>
        <Popover
          trigger="hover"
          placement="topLeft"
          overlayStyle={overlay}
          content={<div className="font-semibold">{t('FORMS.OFFER_ADD.POPOVERS.TOTAL_SURFACE')}</div>}>
          <FormInput
            submitted={submitted}
            label={t('FORMS.OFFER_ADD.NAMES.SURFACE')}
            name={'areaDetails.totalSurface'}
            inputAccepter={InputNumber}
            postfix="m&sup2;"
            min={1}
            step={10}
          />
        </Popover>
      </Panel>
      <Panel header={t('FORMS.OFFER_ADD.NAMES.FLOORS')} defaultExpanded collapsible>
        {Array.from(prop.value.areaDetails.levels).map((level: any, i) => (
          <div key={i}>
            {i != 0 ? <hr /> : null}
            <InputsBox>
              <Popover
                trigger="hover"
                placement="topLeft"
                overlayStyle={overlay}
                content={<div className="font-semibold">{t('FORMS.OFFER_ADD.POPOVERS.LEVEL_DESCRIPTION')}</div>}>
                <FormInput
                  submitted={submitted}
                  label={t('FORMS.OFFER_ADD.NAMES.FLOOR_NUMBER')}
                  name={`areaDetails.levels[${i}].levelNumber`}
                  inputAccepter={InputNumber}
                  min={0}
                  step={1}
                  width={220}
                />
              </Popover>
              <Popover
                trigger="hover"
                placement="topLeft"
                overlayStyle={overlay}
                content={<div className="font-semibold"> {t('FORMS.OFFER_ADD.POPOVERS.LEVEL_DESCRIPTION_NAME')}</div>}>
                <FormInput
                  submitted={submitted}
                  label={t('FORMS.OFFER_ADD.NAMES.FLOOR_NAME')}
                  name={`areaDetails.levels[${i}].levelName`}
                  width={220}
                />
              </Popover>
              <Popover
                trigger="hover"
                placement="topLeft"
                overlayStyle={overlay}
                content={<div className="font-semibold">{t('FORMS.OFFER_ADD.POPOVERS.TOTAL_SURFACE_ON_FLOOR')}</div>}>
                <FormInput
                  submitted={submitted}
                  label={t('FORMS.OFFER_ADD.NAMES.TOTAL_AREA_OF_ROOMS_ON_FLOOR')}
                  name={`areaDetails.levels[${i}].surface`}
                  inputAccepter={InputNumber}
                  postfix="m&sup2;"
                  min={5}
                  step={10}
                  width={220}
                />
              </Popover>
              <Popover
                trigger="hover"
                placement="topLeft"
                overlayStyle={overlay}
                content={<div className="font-semibold">{t('FORMS.OFFER_ADD.POPOVERS.HEIGHT')}</div>}>
                <FormInput
                  submitted={submitted}
                  label={t('FORMS.OFFER_ADD.NAMES.FLOOR_HEIGHT')}
                  name={`areaDetails.levels[${i}].height`}
                  inputAccepter={InputNumber}
                  postfix="cm"
                  min={5}
                  step={10}
                  width={220}
                />
              </Popover>

              <FormInput
                submitted={submitted}
                label={t('FORMS.OFFER_ADD.NAMES.NUMBER_OF_STAIRCASES')}
                name={`areaDetails.levels[${i}].numberOfStaircases`}
                inputAccepter={InputNumber}
                min={0}
                step={1}
                width={220}
              />
            </InputsBox>
            <InputsBox>
              <FormToggle
                submitted={submitted}
                name={`areaDetails.levels[${i}].accessibility`}
                checked={level.accessibility}
                label={t('FORMS.OFFER_ADD.NAMES.DISABLED_ACCESS')}
              />
              <FormToggle
                submitted={submitted}
                name={`areaDetails.levels[${i}].toiletsLocation`}
                checked={level.toiletsLocation}
                label={t('FORMS.OFFER_ADD.NAMES.SHARED_TOILETS_OUTSIDE_PREMISE')}
              />
              <FormToggle
                submitted={submitted}
                name={`areaDetails.levels[${i}].otherRooms`}
                checked={level.otherRooms}
                label={t('LEVELS.OTHER_ROOMS')}
              />
              {selectedOtherRooms[i] && (
                <FormInput
                  submitted={submitted}
                  label=""
                  name={`areaDetails.levels[${i}].otherRoomsDetails`}
                  inputAccepter={CheckPicker}
                  data={otherRoomsOptions}
                  className="-mt-3"
                />
              )}

              <FormToggle
                submitted={submitted}
                name={`areaDetails.levels[${i}].lift`}
                checked={prop.value.areaDetails.levels[i].lift}
                onChange={(checked) => {
                  prop.value.areaDetails.levels[i].lift = checked;
                  if (!checked) {
                    prop.value.areaDetails.levels[i].liftCapacityKg = 0;
                  }
                }}
                label={t('FORMS.OFFER_ADD.NAMES.LIFT')}
              />
            </InputsBox>
            {prop.value.areaDetails.levels[i].lift && (
              <FormInput
                submitted={submitted}
                label={t('FORMS.OFFER_ADD.NAMES.ELEVATOR_CAPACITY')}
                name={`areaDetails.levels[${i}].liftCapacityKg`}
                inputAccepter={InputNumber}
                min={0}
                postfix={'kg'}
                step={100}
              />
            )}

            <Panel header={t('FORMS.OFFER_ADD.NAMES.ROOM_AREA')} defaultExpanded collapsible style={{ margin: 16 }}>
              {level?.offices?.map((room: OfferOfficeDetails, j: number) => (
                <InputsBox key={`offices-${i}-${j}`}>
                  <FormInput
                    submitted={submitted}
                    label={`${t('FORMS.OFFER_ADD.NAMES.OFFICE_SPACE_CATEGORY')} nr ${j + 1}`}
                    name={`areaDetails.levels[${i}].offices[${j}].type`}
                    inputAccepter={InputPicker}
                    data={officeTypeOptions}
                    block
                  />
                  <Popover
                    trigger="hover"
                    placement="topLeft"
                    overlayStyle={overlay}
                    content={<div className="font-semibold">{t('FORMS.OFFER_ADD.POPOVERS.ACCURATE_MEASUREMENT')}</div>}>
                    <FormInput
                      submitted={submitted}
                      label={`${t('FORMS.OFFER_ADD.NAMES.OFFICE_SPACE_AREA')} nr ${j + 1}`}
                      name={`areaDetails.levels[${i}].offices[${j}].surface`}
                      inputAccepter={InputNumber}
                      postfix="m&sup2;"
                      min={1}
                      step={10}
                      onChange={(v) => {
                        room.surface = v;
                        handleSurfaces();
                      }}
                    />
                  </Popover>
                  <div style={{ transform: 'translateY(28px)' }}>
                    <FormToggle
                      submitted={submitted}
                      name={`areaDetails.levels[${i}].offices[${j}].windows`}
                      onChange={(checked) => (room.windows = checked)}
                      checked={room.windows}
                      label={t('FORMS.OFFER_ADD.NAMES.HAS_WINDOWS')}
                    />
                  </div>
                </InputsBox>
              ))}

              <ButtonGroup style={{ marginBottom: 16, display: 'flex', flexDirection: 'column', width: 250 }}>
                <div style={{ display: 'flex' }}>
                  <Button appearance={'ghost'} onClick={() => onAddAreaDetails('offices', i)}>
                    {t('FORMS.OFFER_ADD.NAMES.ADD_OFFICE_SPACE')}
                  </Button>
                  {level?.offices?.length >= 1 ? (
                    <Button
                      appearance={'ghost'}
                      onClick={() => onRemoveAreaDetails('offices', i)}
                      style={{ border: '1px dotted', marginLeft: 5 }}>
                      {t('FORMS.OFFER_ADD.NAMES.REMOVE_OFFICE_SPACE')}
                    </Button>
                  ) : null}
                </div>
              </ButtonGroup>
              {level?.commercialAndServiceRooms?.map((room: OfferCommercialAndServiceRoomDetails, j: number) => (
                <InputsBox key={`commercialAndServiceRooms-${i}-${j}`}>
                  <FormInput
                    submitted={submitted}
                    label={`${t('FORMS.OFFER_ADD.NAMES.COMMERCIAL_AND_SERVICE_SPACE_CATEGORY')} nr ${j + 1}`}
                    name={`areaDetails.levels[${i}].commercialAndServiceRooms[${j}].type`}
                    inputAccepter={InputPicker}
                    data={commercialAndServiceRoomTypeOptions}
                    block
                  />
                  <Popover
                    trigger="hover"
                    placement="topLeft"
                    overlayStyle={overlay}
                    content={<div className="font-semibold">{t('FORMS.OFFER_ADD.POPOVERS.ACCURATE_MEASUREMENT')}</div>}>
                    <FormInput
                      submitted={submitted}
                      label={`${t('FORMS.OFFER_ADD.NAMES.COMMERCIAL_AND_SERVICE_SPACE_AREA')} nr ${j + 1}`}
                      name={`areaDetails.levels[${i}].commercialAndServiceRooms[${j}].surface`}
                      inputAccepter={InputNumber}
                      postfix="m&sup2;"
                      min={1}
                      step={10}
                      onChange={(v) => {
                        room.surface = v;
                        handleSurfaces();
                      }}
                    />
                  </Popover>

                  <div style={{ transform: 'translateY(28px)' }}>
                    <FormToggle
                      submitted={submitted}
                      name={`areaDetails.levels[${i}].commercialAndServiceRooms[${j}].windows`}
                      onChange={(checked) => (room.windows = checked)}
                      checked={room.windows}
                      label={t('FORMS.OFFER_ADD.NAMES.HAS_WINDOWS')}
                    />
                  </div>
                </InputsBox>
              ))}
              <ButtonGroup style={{ marginBottom: 16, display: 'flex', flexDirection: 'column', width: 250 }}>
                <div style={{ display: 'flex' }}>
                  <Button appearance={'ghost'} onClick={() => onAddAreaDetails('commercialAndServiceRooms', i)}>
                    {t('FORMS.OFFER_ADD.NAMES.ADD_COMMERCIAL_AND_SERVICE_SPACE')}
                  </Button>
                  {level?.commercialAndServiceRooms?.length >= 1 ? (
                    <Button
                      appearance={'ghost'}
                      onClick={() => onRemoveAreaDetails('commercialAndServiceRooms', i)}
                      style={{ border: '1px dotted', marginLeft: 5 }}>
                      {t('FORMS.OFFER_ADD.NAMES.REMOVE_COMMERCIAL_AND_SERVICE_SPACE')}
                    </Button>
                  ) : null}
                </div>
              </ButtonGroup>
              {level?.warehouses?.map((room: OfferWarehouseDetails, j: number) => (
                <InputsBox key={`warehouses-${i}-${j}`}>
                  <FormInput
                    submitted={submitted}
                    label={`${t('FORMS.OFFER_ADD.NAMES.WAREHOUSE_SPACE_CATEGORY')} nr ${j + 1}`}
                    name={`areaDetails.levels[${i}].warehouses[${j}].type`}
                    inputAccepter={InputPicker}
                    data={warehouseRoomTypeOptions}
                  />
                  <Popover
                    trigger="hover"
                    placement="topLeft"
                    overlayStyle={overlay}
                    content={<div className="font-semibold">{t('FORMS.OFFER_ADD.POPOVERS.ACCURATE_MEASUREMENT')}</div>}>
                    <FormInput
                      submitted={submitted}
                      label={`${t('FORMS.OFFER_ADD.NAMES.WAREHOUSE_SPACE_AREA')} nr ${j + 1}`}
                      name={`areaDetails.levels[${i}].warehouses[${j}].surface`}
                      inputAccepter={InputNumber}
                      postfix="m&sup2;"
                      min={1}
                      step={10}
                      onChange={(v) => {
                        room.surface = v;
                        handleSurfaces();
                      }}
                    />
                  </Popover>

                  <div style={{ transform: 'translateY(28px)' }}>
                    <FormToggle
                      submitted={submitted}
                      name={`areaDetails.levels[${i}].warehouses[${j}].windows`}
                      onChange={(checked) => (room.windows = checked)}
                      checked={room.windows}
                      label={t('FORMS.OFFER_ADD.NAMES.HAS_WINDOWS')}
                    />
                  </div>
                </InputsBox>
              ))}
              <ButtonGroup style={{ marginBottom: 16, display: 'flex', flexDirection: 'column', width: 250 }}>
                <div style={{ display: 'flex' }}>
                  <Button appearance={'ghost'} onClick={() => onAddAreaDetails('warehouses', i)}>
                    {t('FORMS.OFFER_ADD.NAMES.ADD_WAREHOUSE_SPACE')}
                  </Button>
                  {level?.warehouses?.length >= 1 ? (
                    <Button
                      appearance={'ghost'}
                      onClick={() => onRemoveAreaDetails('warehouses', i)}
                      style={{ border: '1px dotted', marginLeft: 5 }}>
                      {t('FORMS.OFFER_ADD.NAMES.REMOVE_WAREHOUSE_SPACE')}
                    </Button>
                  ) : null}
                </div>
              </ButtonGroup>
              {level?.technicalRooms?.map((room: OfferTechnicalRoomDetails, j: number) => (
                <InputsBox key={`technicalRooms-${i}-${j}`}>
                  <FormInput
                    submitted={submitted}
                    label={`${t('FORMS.OFFER_ADD.NAMES.TECHNICAL_SPACE_CATEGORY')} nr ${j + 1}`}
                    name={`areaDetails.levels[${i}].technicalRooms[${j}].type`}
                    inputAccepter={InputPicker}
                    data={technicalRoomTypeOptions}
                  />
                  <Popover
                    trigger="hover"
                    placement="topLeft"
                    overlayStyle={overlay}
                    content={<div className="font-semibold">{t('FORMS.OFFER_ADD.POPOVERS.ACCURATE_MEASUREMENT')}</div>}>
                    <FormInput
                      submitted={submitted}
                      label={`${t('FORMS.OFFER_ADD.NAMES.TECHNICAL_SPACE_AREA')} nr ${j + 1}`}
                      name={`areaDetails.levels[${i}].technicalRooms[${j}].surface`}
                      inputAccepter={InputNumber}
                      postfix="m&sup2;"
                      min={1}
                      step={10}
                      onChange={(v) => {
                        room.surface = v;
                        handleSurfaces();
                      }}
                    />
                  </Popover>

                  <div style={{ transform: 'translateY(28px)' }}>
                    <FormToggle
                      submitted={submitted}
                      name={`areaDetails.levels[${i}].technicalRooms[${j}].windows`}
                      onChange={(checked) => (room.windows = checked)}
                      checked={room.windows}
                      label={t('FORMS.OFFER_ADD.NAMES.HAS_WINDOWS')}
                    />
                  </div>
                </InputsBox>
              ))}
              <ButtonGroup
                style={{ marginBottom: 16, marginRight: 16, display: 'flex', flexDirection: 'column', width: 250 }}>
                <div style={{ display: 'flex' }}>
                  <Button appearance={'ghost'} onClick={() => onAddAreaDetails('technicalRooms', i)}>
                    {t('FORMS.OFFER_ADD.NAMES.ADD_TECHNICAL_SPACE')}
                  </Button>
                  {level?.technicalRooms?.length >= 1 ? (
                    <Button
                      appearance={'ghost'}
                      onClick={() => onRemoveAreaDetails('technicalRooms', i)}
                      style={{ border: '1px dotted', marginLeft: 4 }}>
                      {t('FORMS.OFFER_ADD.NAMES.REMOVE_TECHNICAL_SPACE')}
                    </Button>
                  ) : null}
                </div>
              </ButtonGroup>
            </Panel>
            {floorSurfaces[i] && (
              <Alert
                message={`Suma powierzchni pomieszczeń na tej kondygnacji wynosi: ${floorSurfaces[i]} m²`}
                type="info"
                showIcon
                className="mb-2"
              />
            )}
          </div>
        ))}
        {sumSurface != surface && surface != null ? (
          <Alert
            message="Suma powierzchni pomieszczeń nie jest równa powierzchni do wynajęcia"
            type="error"
            showIcon
            description={`Suma powierzchni pomieszczeń wynosi ${sumSurface ? sumSurface : 0} m², a powierzchnia Lokalu
            ${surface ? surface : '0 '}
            m²`}
          />
        ) : (
          <Alert
            message={`${t('FORMS.OFFER_ADD.NAMES.TOTAL_PREMISE_AREA')}: ${sumSurface ? sumSurface : 0} m²`}
            type="info"
            showIcon
          />
        )}
        <ButtonGroup style={{ marginTop: 16 }}>
          <Button appearance={'ghost'} onClick={() => onAddAreaDetails(prop.value.areaDetails.levels)}>
            {t('FORMS.OFFER_ADD.NAMES.ADD_FLOOR')}
          </Button>
          {prop.value.areaDetails.levels.length >= 1 && (
            <Button appearance={'ghost'} onClick={() => onRemoveAreaDetails(prop.value.areaDetails.levels)}>
              {t('FORMS.OFFER_ADD.NAMES.REMOVE_FLOOR')}
            </Button>
          )}
        </ButtonGroup>
      </Panel>
      <Panel header={t('FORMS.OFFER_ADD.NAMES.UTILITIES')} defaultExpanded collapsible>
        <RowBox>
          <Popover
            trigger="hover"
            placement="topLeft"
            overlayStyle={overlay}
            content={<div className="font-semibold">{t('FORMS.OFFER_ADD.POPOVERS.CURRENT_OR_MAX')}</div>}>
            <FormInput
              submitted={submitted}
              label={t('FORMS.OFFER_ADD.NAMES.POWER_ALLOCATION')}
              name={'mediaDetails.powerAllocation'}
              inputAccepter={InputNumber}
              postfix="kW"
              min={2}
              step={10}
            />
          </Popover>
          <Popover
            trigger="hover"
            placement="topLeft"
            overlayStyle={overlay}
            content={<div className="font-semibold">{t('FORMS.OFFER_ADD.POPOVERS.ENERGY_CERTIFICATE')}</div>}>
            <FormInput
              submitted={submitted}
              label={t('FORMS.OFFER_ADD.NAMES.ENERGY_CATEGORY')}
              name="mediaDetails.energyCategory"
              inputAccepter={SelectPicker}
              data={energyCategoryOptions}
            />
          </Popover>
        </RowBox>
        <InputsBox>
          <FormToggle
            submitted={submitted}
            name="mediaDetails.airConditioning"
            checked={prop.value.mediaDetails.airConditioning}
            label={t('FORMS.OFFER_ADD.NAMES.AIR_CONDITIONING')}
          />
          <FormToggle
            submitted={submitted}
            name="mediaDetails.gravityVentilation"
            checked={prop.value.mediaDetails.gravityVentilation}
            label={t('FORMS.OFFER_ADD.NAMES.GRAVITY_VENTILATION')}
          />
          <FormToggle
            submitted={submitted}
            name="mediaDetails.heatRecovery"
            checked={prop.value.mediaDetails.heatRecovery}
            label={t('FORMS.OFFER_ADD.NAMES.HEAT_RECOVERY')}
          />
          <FormToggle
            submitted={submitted}
            name={`mediaDetails.transformerIn`}
            checked={prop.value.mediaDetails.transformerIn}
            label={t('FORMS.OFFER_ADD.NAMES.TRANSFORMER_STATION_ON_PLOT')}
          />
          <FormToggle
            submitted={submitted}
            name={`mediaDetails.doublePowerLines`}
            checked={prop.value.mediaDetails.doublePowerLines}
            label={t('FORMS.OFFER_ADD.NAMES.DUAL_POWER_SUPPLY')}
          />
          <FormToggle
            submitted={submitted}
            name={`mediaDetails.water`}
            checked={prop.value.mediaDetails.water}
            label={t('FORMS.OFFER_ADD.NAMES.WATER')}
          />
        </InputsBox>
        <InputsBox>
          <FormToggle
            submitted={submitted}
            name={`mediaDetails.gas`}
            checked={prop.value.mediaDetails.gas}
            label={t('FORMS.OFFER_ADD.NAMES.GAS')}
          />

          <FormToggle
            submitted={submitted}
            name={`mediaDetails.sewerage`}
            checked={prop.value.mediaDetails.sewerage}
            label={t('FORMS.OFFER_ADD.NAMES.SEWAGE')}
          />
          <FormToggle
            submitted={submitted}
            name={`mediaDetails.heating`}
            checked={prop.value.mediaDetails.heating}
            label={t('FORMS.OFFER_ADD.NAMES.HEATING')}
          />
          <FormToggle
            submitted={submitted}
            name={`mediaDetails.fiber`}
            checked={prop.value.mediaDetails.fiber}
            label={t('FORMS.OFFER_ADD.NAMES.FIBER_OPTIC')}
          />
          <FormToggle
            submitted={submitted}
            name={`mediaDetails.LAN`}
            checked={prop.value.mediaDetails.LAN}
            label={t('FORMS.OFFER_ADD.NAMES.COMPUTER_NETWORK')}
          />
          <FormToggle
            submitted={submitted}
            name={`mediaDetails.phoneLine`}
            checked={prop.value.mediaDetails.phoneLine}
            label={t('FORMS.OFFER_ADD.NAMES.TELEPHONE_NETWORK')}
          />
        </InputsBox>
      </Panel>
      {legalMainUse == 'Magazynowo-Przemysłowe' ? (
        <Panel header={t('FORMS.OFFER_ADD.NAMES.OTHER')} defaultExpanded collapsible>
          {prop.value.relatedDetails.gates
            ? prop.value.relatedDetails.gates.map((l, i) => (
                <RowBox key={i} style={{ marginBottom: 4 }}>
                  <FormInput
                    submitted={submitted}
                    label={t('FORMS.OFFER_ADD.NAMES.GATE_WIDTH') + ' ' + (i + 1)}
                    name={`relatedDetails.gates[${i}].width`}
                    placeholder={t('WIDTH')}
                    inputAccepter={InputNumber}
                    min={0}
                    postfix={'cm'}
                    style={{ marginRight: 8 }}
                  />
                  <FormInput
                    submitted={submitted}
                    label={t('FORMS.OFFER_ADD.NAMES.GATE_HEIGHT') + ' ' + (i + 1)}
                    name={`relatedDetails.gates[${i}].height`}
                    placeholder={t('HEIGHT')}
                    inputAccepter={InputNumber}
                    min={0}
                    postfix={'cm'}
                  />
                </RowBox>
              ))
            : null}
          <ButtonGroup style={{ marginBottom: 16 }}>
            <Button appearance={'ghost'} onClick={onAddGate}>
              {t('FORMS.OFFER_ADD.NAMES.ADD_GATE')}
            </Button>
            {prop.value.relatedDetails.gates
              ? prop.value.relatedDetails.gates.length >= 1 && (
                  <Button appearance={'ghost'} onClick={onRemoveGate}>
                    {t('FORMS.OFFER_ADD.NAMES.REMOVE_GATE')}
                  </Button>
                )
              : null}
          </ButtonGroup>
          <hr />
          <br></br>
          <RowBox style={{ marginBottom: 4 }}>
            <FormInput
              submitted={submitted}
              label={t('FORMS.OFFER_ADD.NAMES.WAREHOUSE_EQUIPMENT')}
              name={'relatedDetails.warehouseEquipment'}
              inputAccepter={CheckPicker}
              data={warehouseEquipment}
            />
            <FormInput
              submitted={submitted}
              label={t('FORMS.OFFER_ADD.NAMES.HALL_HEIGHT_TO_CONSTRUCTION')}
              name={'relatedDetails.hallHeight'}
              inputAccepter={InputNumber}
              min={1}
              postfix={'cm'}
              style={{ marginLeft: 8 }}
            />
          </RowBox>
          <InputsBox>
            <FormToggle
              submitted={submitted}
              name={`relatedDetails.crane`}
              checked={prop.value.relatedDetails.crane}
              label={t('FORMS.OFFER_ADD.NAMES.CRANE')}
            />
            <FormToggle
              submitted={submitted}
              name={`relatedDetails.carRamp`}
              checked={prop.value.relatedDetails.carRamp}
              label={t('FORMS.OFFER_ADD.NAMES.CAR_RAMP')}
            />
            <FormToggle
              submitted={submitted}
              name={`relatedDetails.warehouseHandling`}
              checked={prop.value.relatedDetails.warehouseHandling}
              label={t('FORMS.OFFER_ADD.NAMES.WAREHOUSE_SERVICE')}
            />
            <FormToggle
              submitted={submitted}
              name={`relatedDetails.highBayRacking`}
              checked={prop.value.relatedDetails.highBayRacking}
              label={t('FORMS.OFFER_ADD.NAMES.HIGH_BAY_RACKING')}
            />
          </InputsBox>
          <InputsBox>
            <FormToggle
              submitted={submitted}
              name={`relatedDetails.forklift`}
              checked={prop.value.relatedDetails.forklift}
              label={t('FORMS.OFFER_ADD.NAMES.FORKLIFT')}
            />
            <FormToggle
              submitted={submitted}
              name={`relatedDetails.mechanicalVentilation`}
              checked={prop.value.relatedDetails.mechanicalVentilation}
              label={t('FORMS.OFFER_ADD.NAMES.MECHANICAL_VENTILATION')}
            />
            <FormToggle
              submitted={submitted}
              name={`relatedDetails.groundLevelWarehouse`}
              checked={prop.value.relatedDetails.groundLevelWarehouse}
              label={t('FORMS.OFFER_ADD.NAMES.WAREHOUSE_ON_GROUND_LEVEL')}
            />
            <FormToggle
              submitted={submitted}
              name={`relatedDetails.windowOpenings`}
              checked={prop.value.relatedDetails.windowOpenings}
              label={t('FORMS.OFFER_ADD.NAMES.WINDOW_OPENINGS')}
            />
          </InputsBox>
          <InputsBox>
            <FormToggle
              submitted={submitted}
              name={`relatedDetails.dustFreeFloor`}
              checked={prop.value.relatedDetails.dustFreeFloor}
              label={t('FORMS.OFFER_ADD.NAMES.DUST_FREE_FLOOR')}
            />
            <FormToggle
              submitted={submitted}
              name={`relatedDetails.TIRHallEntrance`}
              checked={prop.value.relatedDetails.TIRHallEntrance}
              label={t('FORMS.OFFER_ADD.NAMES.TIR_HALL_ENTRANCE')}
            />
            <FormToggle
              submitted={submitted}
              name={`relatedDetails.railRamp`}
              checked={prop.value.relatedDetails.railRamp}
              label={t('FORMS.OFFER_ADD.NAMES.RAIL_RAMP')}
            />

            <FormToggle
              submitted={submitted}
              name={`relatedDetails.naturalVentilation`}
              checked={prop.value.relatedDetails.naturalVentilation}
              label={t('FORMS.OFFER_ADD.NAMES.NATURAL_VENTILATION')}
            />
          </InputsBox>
          <RowBoxWithMargin>
            <FormToggle
              submitted={submitted}
              name="columnsGridStructure"
              onChange={() => {
                if (prop.value.relatedDetails.columnsGridStructure != '') {
                  prop.value.relatedDetails.columnsGridStructure = '';
                } else {
                  prop.value.relatedDetails.columnsGridStructure = { x: null, y: null };
                }
              }}
              checked={!!prop.value.relatedDetails.columnsGridStructure}
              accepter={Toggle}
              style={{ float: 'left', marginRight: 8 }}
              label={t('FORMS.OFFER_ADD.NAMES.COLUMNS_GRID')}
            />
          </RowBoxWithMargin>

          {prop.value.relatedDetails.columnsGridStructure && (
            <RowBox>
              <FormInput
                submitted={submitted}
                label={t('FORMS.OFFER_ADD.NAMES.CONSTRUCTION_POST_GRID_WIDTH')}
                name={`relatedDetails.columnsGridStructure.x`}
                placeholder="X"
                inputAccepter={InputNumber}
                min={0}
                postfix={'cm'}
              />
              <FormInput
                submitted={submitted}
                label={t('FORMS.OFFER_ADD.NAMES.CONSTRUCTION_POST_GRID_HEIGHT')}
                name={`relatedDetails.columnsGridStructure.y`}
                placeholder="Y"
                inputAccepter={InputNumber}
                min={0}
                postfix={'cm'}
              />
            </RowBox>
          )}
        </Panel>
      ) : legalMainUse == 'Biurowe' ? (
        <Panel header={t('FORMS.OFFER_ADD.NAMES.OTHER')} defaultExpanded collapsible>
          <InputsBox>
            <FormToggle
              submitted={submitted}
              name="relatedDetails.raisedFloors"
              checked={prop.value.relatedDetails.raisedFloors}
              label="Podłogi uniesione"
            />
            <FormToggle
              submitted={submitted}
              name="relatedDetails.raisedCeilings"
              checked={prop.value.relatedDetails.raisedCeilings}
              label="Sufity podwyższone"
            />
            <FormToggle
              submitted={submitted}
              name="relatedDetails.alarm"
              checked={prop.value.relatedDetails.alarm}
              label="Alarm z czujnikami ruchu"
            />
            <FormToggle
              submitted={submitted}
              name="relatedDetails.monitoring"
              checked={prop.value.relatedDetails.monitoring}
              label="Monitoring video"
            />
            <FormToggle
              submitted={submitted}
              name="relatedDetails.openingWindows"
              checked={prop.value.relatedDetails.openingWindows}
              label="Okna otwierane"
            />
          </InputsBox>
          <FormInput
            submitted={submitted}
            label={t('FORMS.OFFER_ADD.NAMES.OFFICE_EQUIPMENT')}
            name="relatedDetails.officeEquipment"
            inputAccepter={CheckPicker}
            data={officeEquipment}
          />
        </Panel>
      ) : legalMainUse == 'Handlowo-Usługowe' ? (
        <Panel header={t('FORMS.OFFER_ADD.NAMES.OTHER')} defaultExpanded collapsible>
          <InputsBox>
            <FormToggle
              submitted={submitted}
              name="relatedDetails.anyArrangement"
              checked={prop.value.relatedDetails.anyArrangement}
              label="Dowolna aranżacja"
            />
            <FormToggle
              submitted={submitted}
              name="relatedDetails.independentVentilationChannel"
              checked={prop.value.relatedDetails.independentVentilationChannel}
              label="Niezależny kanał wentylacyjny"
            />
            <FormToggle
              submitted={submitted}
              name="columnsGridStructure"
              onChange={() => {
                if (prop.value.relatedDetails.columnsGridStructure != null) {
                  prop.value.relatedDetails.columnsGridStructure = null;
                } else {
                  prop.value.relatedDetails.columnsGridStructure = { x: null, y: null };
                }
              }}
              checked={!!prop.value.relatedDetails.columnsGridStructure}
              accepter={Toggle}
              style={{ float: 'left', marginRight: 8 }}
              label="Słupy wewnątrz hali"></FormToggle>
          </InputsBox>
          {prop.value.relatedDetails.columnsGridStructure && (
            <RowBox>
              <FormInput
                submitted={submitted}
                label={t('FORMS.OFFER_ADD.NAMES.CONSTRUCTION_POST_GRID_WIDTH')}
                name={`relatedDetails.columnsGridStructure.x`}
                placeholder="X"
                inputAccepter={InputNumber}
                min={0}
                postfix={'cm'}
              />
              <FormInput
                submitted={submitted}
                label={t('FORMS.OFFER_ADD.NAMES.CONSTRUCTION_POST_GRID_HEIGHT')}
                name={`relatedDetails.columnsGridStructure.y`}
                placeholder="Y"
                inputAccepter={InputNumber}
                min={0}
                postfix={'cm'}
              />
            </RowBox>
          )}
          <FormInput
            submitted={submitted}
            label="Ilość wejść"
            name={`relatedDetails.numberOfEntries`}
            inputAccepter={InputNumber}
            min={1}
            postfix={'szt'}
          />
        </Panel>
      ) : null}
      <Panel header={t('FORMS.OFFER_ADD.NAMES.PARKING')} defaultExpanded collapsible>
        <InputsBox>
          <Popover
            trigger="hover"
            placement="topLeft"
            overlayStyle={overlay}
            content={<div className="font-semibold">{t('FORMS.OFFER_ADD.POPOVERS.PARKING_AREA')}</div>}>
            <FormInput
              submitted={submitted}
              label={t('FORMS.OFFER_ADD.NAMES.PARKING_AREA')}
              name={'parkingDetails.parkingSurface'}
              inputAccepter={InputNumber}
              postfix="m&sup2;"
              min={0}
              step={10}
            />
          </Popover>

          <FormInput
            submitted={submitted}
            label={t('FORMS.OFFER_ADD.NAMES.TOTAL_PARKING_AREA')}
            name={'parkingDetails.totalParkingSurface'}
            inputAccepter={InputNumber}
            min={0}
            step={10}
            postfix="szt"
          />
          <Popover
            trigger="hover"
            placement="topLeft"
            overlayStyle={overlay}
            content={<div className="font-semibold">{t('FORMS.OFFER_ADD.POPOVERS.RENTAL_AREA')}</div>}>
            <FormInput
              submitted={submitted}
              label={t('FORMS.OFFER_ADD.NAMES.NUMBER_OF_PARKING_SPOTS_ASSIGNED_TO_PREMISE')}
              name={'parkingDetails.parkingLotsAvailable'}
              inputAccepter={InputNumber}
              min={0}
              step={10}
              postfix="szt"
            />
          </Popover>

          <div style={{ transform: 'translateY(28px)' }}>
            <FormToggle
              submitted={submitted}
              name={'parkingDetails.guardedParking'}
              checked={prop.value.parkingDetails.guardedParking}
              label={t('FORMS.OFFER_ADD.NAMES.EXCLUSIVELY_AVAILABLE_TO_TENANT')}
            />
          </div>
        </InputsBox>
      </Panel>
      {children}
      <Panel header={t('FORMS.OFFER_ADD.NAMES.LEASE_AGREEMENT_CHOICE')} defaultExpanded collapsible>
        <RowBox>
          <Popover
            trigger="hover"
            placement="topLeft"
            overlayStyle={overlay}
            content={<div className="font-semibold">{t('FORMS.OFFER_ADD.POPOVERS.OFFER_VALID_TO')}</div>}>
            <FormInput
              submitted={submitted}
              label={t('FORMS.OFFER_ADD.NAMES.LEASE_VALID_TO')}
              name={`offerValidTo`}
              inputAccepter={DatePicker}
              isoWeek={true}
              oneTap={true}
              highlighted
              onClean={(event) => {
                event.preventDefault();
                prop.value.offerValidTo = null;
              }}
            />
          </Popover>
          <Popover
            trigger="hover"
            placement="topLeft"
            overlayStyle={overlay}
            content={<div className="font-semibold">{t('FORMS.OFFER_ADD.POPOVERS.RECEIPT_DEADLINE')}</div>}>
            <FormInput
              submitted={submitted}
              label={t('FORMS.OFFER_ADD.NAMES.UNIT_RECEIPT_DEADLINE')}
              name={'unitReceiptDeadline'}
              placeholder={t('FORMS.OFFER_ADD.PLACEHOLDERS.UNIT_RECEIPT_DEADLINE')}
              inputAccepter={InputNumber}
              min={7}
              postfix={'dni'}
            />
          </Popover>
          <Popover
            trigger="hover"
            placement="topLeft"
            overlayStyle={overlay}
            content={<div className="font-semibold">{t('FORMS.OFFER_ADD.POPOVERS.DEPOSIT')}</div>}>
            <FormInput
              submitted={submitted}
              label={t('FORMS.OFFER_ADD.NAMES.DEPOSIT_WITH_VAT')}
              name={'leaseAgreement.deposit'}
              placeholder={t('FORMS.OFFER_ADD.PLACEHOLDERS.DEPOSIT_WITH_VAT')}
              inputAccepter={InputNumber}
            />
          </Popover>
          <Popover
            trigger="hover"
            placement="topLeft"
            overlayStyle={overlay}
            content={<div className="font-semibold">{t('FORMS.OFFER_ADD.POPOVERS.RENT_PAYMENT_DEADLINE')}</div>}>
            <FormInput
              submitted={submitted}
              label={t('FORMS.OFFER_ADD.NAMES.RENT_PAYMENT_DEADLINE')}
              name={'leaseAgreement.payDay'}
              placeholder={t('FORMS.OFFER_ADD.PLACEHOLDERS.RENT_PAYMENT_DEADLINE')}
              inputAccepter={InputNumber}
              min={1}
              postfix={'dzień'}
            />
          </Popover>
        </RowBox>
        <RowBox style={{ marginBottom: 16 }}>
          <Popover
            trigger="hover"
            placement="topLeft"
            overlayStyle={{ width: '25vh' }}
            content={<div className="font-semibold">{t('FORMS.OFFER_ADD.POPOVERS.DAYS_FROM_RECEIPT')}</div>}>
            <FormInput
              submitted={submitted}
              label={t('FORMS.OFFER_ADD.NAMES.FIRST_RENT_PAYMENT_FROM')}
              name={'leaseAgreement.firstPaymentDay'}
              placeholder={t('FORMS.OFFER_ADD.PLACEHOLDERS.FIRST_RENT_PAYMENT_FROM')}
              inputAccepter={InputNumber}
              min={1}
              postfix={'dzień'}
            />
          </Popover>
          <FormInput
            submitted={submitted}
            label={t('FORMS.OFFER_ADD.NAMES.NON_RENT_FEES')}
            name={'leaseAgreement.nonRentFees'}
            inputAccepter={SelectPicker}
            data={nonRentFeesOptions}
            searchable={false}
          />
          {selectedFeeType == 'Licznikowe' && (
            <RowBox style={{ marginBottom: 16 }}>
              <Popover
                trigger="hover"
                placement="topLeft"
                overlayStyle={overlay}
                content={<div className="font-semibold">{t('FORMS.OFFER_ADD.POPOVERS.PRICE_GROSS')}</div>}>
                <FormInput
                  submitted={submitted}
                  label={t('FORMS.OFFER_ADD.NAMES.AVERAGE_ANNUAL_COSTS')}
                  name={'leaseAgreement.averageMonthlyCostsAnnualized'}
                  accepter={InputNumber}
                  min={0}
                  disabled={prop.value.leaseAgreement.averageMonthlyCostsAnnualizedKnown}
                  postfix={'PLN'}
                />
              </Popover>

              <div style={{ transform: 'translateY(28px)' }}>
                <FormToggle
                  submitted={submitted}
                  name={'leaseAgreement.averageMonthlyCostsAnnualizedKnown'}
                  checked={prop.value.leaseAgreement.averageMonthlyCostsAnnualizedKnown}
                  label={t('FORMS.OFFER_ADD.NAMES.UNKNOWN_COSTS')}
                />
              </div>
            </RowBox>
          )}
          {selectedFeeType == 'Ryczałtowe' && (
            <RowBox style={{ marginBottom: 16 }}>
              <FormInput
                submitted={submitted}
                label={t('FORMS.OFFER_ADD.NAMES.LUMP_SUM_PER_SQM')}
                name={'leaseAgreement.lumpSum'}
                accepter={InputNumber}
                min={0}
              />
            </RowBox>
          )}
        </RowBox>
        <Typography.Body1 style={{ display: 'block', marginBottom: 16 }}>
          {t('FORMS.OFFER_ADD.NAMES.MULTIPLE_OPTION_CHOICE_GENERATES_TWO_OFFERS')}
        </Typography.Body1>
        <RowBox style={{ marginBottom: 16 }} className="flex-row">
          <FormToggle
            submitted={submitted}
            name={'leaseAgreement.infinite'}
            checked={prop.value.leaseAgreement.infinite}
            label={t('FORMS.OFFER_ADD.NAMES.INDEFINITE_PERIOD_LEASE_AGREEMENT')}></FormToggle>
          {selectedInfinite && (
            <div className="transform -translate-y-3">
              <FormInput
                submitted={submitted}
                label=""
                name={'leaseAgreement.leaseNoticePeriod'}
                accepter={InputNumber}
                min={0}
                max={36}
                placeholder={t('FORMS.OFFER_ADD.PLACEHOLDERS.NOTICE_PERIOD')}
                postfix={'mies.'}
              />
            </div>
          )}
        </RowBox>
        <RowBox style={{ marginBottom: 16 }} className="flex-row">
          <FormToggle
            submitted={submitted}
            name={'leaseAgreement.fixedshort'}
            checked={prop.value.leaseAgreement.fixedshort}
            label={t('FORMS.OFFER_ADD.NAMES.LEASE_AGREEMENT_UP_TO_12_MONTHS')}></FormToggle>
          {selectedFixedshort && (
            <div className="transform -translate-y-3">
              <FormInput
                submitted={submitted}
                label=""
                name={'leaseAgreement.fixedshortMonths'}
                accepter={InputNumber}
                min={1}
                max={12}
                placeholder={t('FORMS.OFFER_ADD.PLACEHOLDERS.NUMBER_OF_MONTHS')}
                postfix={'mies.'}
              />
            </div>
          )}
        </RowBox>
        {/* <RowBox style={{ marginBottom: 16 }}>
          <Popover
            className="flex flex-row"
            trigger="hover"
            placement="top"
            overlayStyle={{ width: '30vh' }}
            content={
              <div className="font-semibold">podpisanie Umowy będzie wymagało ręcznego lub kwalifikowanego podpisu</div>
            }>
            <FormToggle submitted={submitted}
              name={'leaseAgreement.fixedlong'}
              checked={prop.value.leaseAgreement.fixedlong}
              label={t('FORMS.OFFER_ADD.NAMES.LEASE_AGREEMENT_MORE_THAN_12_MONTHS')}
              disabled={monthDiff(prop.value.availability, prop.value.availabilityTo, prop)}></FormToggle>
            {selectedFixedlong && (
              <div className="transform -translate-y-3">
                <FormInput submitted={submitted}
                  label=""
                  className={'ml-2'}
                  name={'leaseAgreement.fixedlongMonths'}
                  accepter={InputNumber}
                  min={13}
                  placeholder="Liczba miesięcy"
                  postfix={'mies.'}
                />
              </div>
            )}
          </Popover>
        </RowBox> */}
        <div>
          <div className={`${selectedFixedlong ? '-mt-6' : ''} py-3`}>
            {t('FORMS.OFFER_ADD.OWN_TERMS.ATTACH_OWN_LEASE_TERMS')}
          </div>
          <FormToggle
            submitted={submitted}
            name={'ownTerms.selected'}
            checked={prop.value.ownTerms.selected}
            label={t('FORMS.OFFER_ADD.OWN_TERMS.ATTACH_OWN_LEASE_TERMS')}
          />
          {selectedOwnTerms && (
            <BasicInputs>
              <FormInput
                submitted={submitted}
                label={t('FORMS.OFFER_ADD.OWN_TERMS.LEASE_TERMS')}
                name={'ownTerms.terms'}
                inputAccepter={Input}
                placeholder={t('FORMS.OFFER_ADD.PLACEHOLDERS.LEASE_TERMS')}
                componentClass="textarea"
                rows={25}
                style={{ width: '105%' }}
              />
            </BasicInputs>
          )}

          {/* <FormToggle submitted={submitted}
            name={'counteroffer.selected'}
            checked={prop.value.counteroffer.selected}
            label={
              <LocalizedLabelWithMultipleLinks
                i18nKey="FORMS.OFFER_ADD.NAMES.COUNTEROFFER"
                linkPaths={{
                  '1': `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_STORAGE_BUCKET}/o/documents%2FFL_Regulamin.pdf?alt=media`,
                }}
              />
            }></FormToggle> */}
        </div>
      </Panel>
      <Panel header={t('FORMS.OFFER_ADD.NAMES.SUMMARY')} defaultExpanded collapsible>
        <FormToggle
          submitted={submitted}
          name={'summary.summary1'}
          checked={prop.value.summary.summary1}
          label={
            <LocalizedLabelWithMultipleModals
              i18nKey="FORMS.OFFER_ADD.NAMES.DECLARATION_AGREEMENT_TEXT_REFLECTS_MY_WILL_INTENTION"
              linkPaths={{
                '1': 'documents/FL_AspektyPrawne.pdf',
              }}
            />
          }
        />
        <FormToggle
          submitted={submitted}
          name={'summary.summary2'}
          checked={prop.value.summary.summary2}
          label={t('FORMS.OFFER_ADD.NAMES.DECLARATION_ACCEPT_TENANT_ELECTRONIC_SIGNATURE')}></FormToggle>
        <FormToggle
          submitted={submitted}
          name={'summary.summary3'}
          checked={prop.value.summary.summary3}
          label={t('FORMS.OFFER_ADD.NAMES.OFFER_DECLARATION_ARTICLE_66_CC')}></FormToggle>
        <FormToggle
          submitted={submitted}
          name={'summary.summary4'}
          checked={prop.value.summary.summary4}
          label={
            <>
              <LocalizedLabelWithMultipleModals
                i18nKey="FORMS.OFFER_ADD.NAMES.CONFIRMATION_OFFER_CANNOT_BE_WITHDRAWN"
                linkPaths={{
                  '1': 'documents/FL_Regulamin.pdf',
                  '2': 'documents/FL_Regulamin-Wynajmujacy.pdf',
                  '3': 'documents/FL_Cennik.pdf',
                }}
              />
              {prop.value.offerValidTo?.toLocaleTimeString('pl-PL', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
              })}
            </>
          }></FormToggle>
      </Panel>
    </PanelGroup>
  );
};
