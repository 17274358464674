import { Company } from '../features/MojaTablica/Account/Identity/LinkCompany/company.models';

import { Verification } from './offers.model';

export enum Currency {
  PLN = 'PLN',
}

export interface SelectOption {
  label: string;
  value: string;
  role?: string;
}

export interface UserVerified {
  verified: boolean;
  message: string;
  description: string;
  action: () => void;
  routeName: string;
}

export enum OfferSigningStatus {
  PROJECT = 'Projekt Oferty',
  OFFER_CERTIFICATE = 'Certyfikat Oferty',
  LEASE_OFFER_CERTIFICATE = 'Certyfikat Umowy Najmu',
}

export interface User {
  email: string;
  firstName: string;
  lastName: string;
  marketingConsent: boolean;
  uid: string;
  verification: { company: Verification; identity: Verification };
  company: Company;
}
